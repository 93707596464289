import type { Optional } from '@/models/utils'

export const EmptyGuid = '00000000-0000-0000-0000-000000000000'

export const replaceItemAt = <T>(arr: Array<T>, item: T, index: number): Array<T> => {
  return [...arr.slice(0, index), item, ...arr.slice(index + 1)]
}

export const parseAdvancedFloat = (val: Optional<string>) => {
  if (!val) return undefined
  return parseFloat(val)
}
