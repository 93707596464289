<script setup lang="ts">
// Path to icon in Healthicons e.g. medications/medicines
// Size of icon in rem
import { onMounted, ref } from 'vue'

const props = defineProps<{ iconPath: keyof typeof iconMap; size: number }>()
const iconMap: Record<string, Promise<{ default: unknown }>> = {
  medicines: import('healthicons/public/icons/svg/filled/medications/medicines.svg')
  // Add more icons as needed
} as const

const icon = ref()
const iconWrapper = ref()
onMounted(async () => {
  icon.value = await (await iconMap[props.iconPath]).default
})
</script>

<template>
  <div ref="iconWrapper">
    <component
      :is="icon"
      :width="props.size + 'rem'"
      :height="props.size + 'rem'"
      viewBox="0 0 48 48"
    />
  </div>
</template>

<style scoped></style>
