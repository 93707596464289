import { useEnv } from '@/composables/enviromentComposable'
import { createFetch } from '@vueuse/core'
import { useToast } from 'primevue/usetoast'
import type { Ref } from 'vue'
import Keycloak from 'keycloak-js'

const env = useEnv()

export interface StammdatenApiOptions {
  isLoading?: Ref<boolean>
  basePath?: string
}

export const keycloak = new Keycloak({
  url: env.KEYCLOAK_AUTH_URI, // Keycloak server URL
  realm: env.KEYCLOAK_REALM, // Your realm name
  clientId: env.KEYCLOAK_CLIENT_ID // Your client ID
})

const checkTokens = async () => {
  try {
    await keycloak.updateToken(5)
  } catch (error) {
    ;('Failed to refresh the token.')
  }
}

export function useStammdatenApi({ isLoading, basePath }: StammdatenApiOptions = {}) {
  const toast = useToast()

  return createFetch({
    /*baseUrl: basePath ? `${env.STAMMDATEN_API_URL}/${basePath}` : env.STAMMDATEN_API_URL,*/ //todo

    baseUrl: 'api/',
    mode: 'no-cors',
    options: {
      async beforeFetch({ options }) {
        if (isLoading) isLoading.value = true
        await checkTokens()
        ;(options.headers as Record<string, string>)['Authorization'] = `Bearer ${keycloak.token}`
        return { options }
      },
      afterFetch(ctx) {
        if (isLoading) isLoading.value = false
        return ctx
      },
      onFetchError(ctx) {
        toast.add({ severity: 'error', summary: 'Error', detail: ctx.error, life: 3000 })
        console.error(ctx.response?.url, ctx.error, ctx.data, ctx.response?.json())
        ctx.data = null
        return ctx
      }
    }
  })
}
