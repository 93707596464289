import { defineStore } from 'pinia'
import { computed, readonly, type Ref, ref, watch } from 'vue'
import type { AnalyseMetaResponse } from '@/stores/regelgruppen/AnalyseMetaResponse'
import { useStammdatenApi } from '@/composables/stammdatenApiComposable'
import type { PartialWithRequiredFields } from '@/models/utils'
import type { UpdateAnalyseRequest } from '@/stores/analysen/UpdateAnalyseRequest'
import { v4 as uuidv4 } from 'uuid'

export const useAnalyseStore = defineStore('analysen', () => {
  const stammdatenApi = useStammdatenApi()

  const analysenState = ref<AnalyseMetaResponse[]>([])
  const isLoadingState = ref(false)
  const selectedItemId = ref<string>()

  const fetchAnalysen = async () => {
    const req = stammdatenApi('Analysen').get().json()
    const { data, isFetching, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()

    if (error.value) return
    analysenState.value = data.value as AnalyseMetaResponse[]
  }

  const analysenCount = computed(() => analysenState.value.length)

  const addAnalyse = async ({
    kuerzel,
    beschreibung,
    einheit
  }: {
    kuerzel: string
    beschreibung: string
    einheit: string
  }) => {
    const temporaryGuid = uuidv4()
    const newAnalyse = {
      id: temporaryGuid,
      updateDateTime: undefined,
      kuerzel: kuerzel,
      beschreibung: beschreibung,
      einheit: einheit,
      regelgruppeNames: []
    } as AnalyseMetaResponse

    analysenState.value.push(newAnalyse)

    const req = stammdatenApi<AnalyseMetaResponse>('Analysen/', {})
      .post({
        kuerzel,
        beschreibung,
        einheit
      })
      .json()
    const { isFetching, data, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()
    if (error.value || !data.value) {
      analysenState.value = analysenState.value.filter((x) => x.id == temporaryGuid)
      return
    }
    const a = analysenState.value.find((x) => x.id == temporaryGuid)
    if (a) a.id = data.value.id
    selectedItemId.value = data.value.id
  }

  const deleteAnalyse = async (id: string) => {
    const analyseIndex = analysenState.value.findIndex((a) => a.id === id)
    const analyseToDelete = analysenState.value.at(analyseIndex)
    if (analyseToDelete) analysenState.value.splice(analyseIndex, 1)

    const req = stammdatenApi(`Analysen/${id}`).delete()
    const { isFetching, error } = req

    if (selectedItemId.value === id) selectedItemId.value = undefined
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()
    if (error.value) {
      analysenState.value.splice(analyseIndex, 0, analyseToDelete)
    }
    await fetchAnalysen()
  }

  const copyItem = async (id: string, kuerzel: string, beschreibung: string, einheit: string) => {
    const req = stammdatenApi<AnalyseMetaResponse>(`Analysen/Copy/${id}`)
      .post({ kuerzel, beschreibung, einheit })
      .json()
    const { data, error, isFetching } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()
    if (error.value || !data.value) return
    analysenState.value.unshift(data.value)
  }

  const updateAnalyse = async (changes: PartialWithRequiredFields<AnalyseMetaResponse, 'id'>) => {
    const oldItem = analysenState.value.find((i) => i.id === changes.id)
    if (!oldItem) return
    const newItem = {
      id: changes.id,
      kuerzel: changes?.kuerzel ?? oldItem?.kuerzel,
      beschreibung: changes?.beschreibung ?? oldItem?.beschreibung,
      einheit: changes?.einheit ?? oldItem?.einheit
    } as AnalyseMetaResponse

    const preparedItem: UpdateAnalyseRequest = {
      id: newItem?.id,
      kuerzel: newItem?.kuerzel,
      beschreibung: newItem?.beschreibung,
      einheit: newItem?.einheit
    }
    const existsInState = updateDataInAnalysenById(analysenState, newItem)

    const req = stammdatenApi<AnalyseMetaResponse>(`Analysen`).put(preparedItem)
    const { isFetching, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()

    if (error.value) {
      if (existsInState) updateDataInAnalysenById(analysenState, oldItem)
      return
    }
  }

  const updateDataInAnalysenById = (
    analysen: Ref<AnalyseMetaResponse[]>,
    data: AnalyseMetaResponse
  ) => {
    const index = analysen.value.findIndex((r) => r.id === data.id)
    if (index === -1) {
      return false
    } else {
      analysen.value[index] = data
      return true
    }
  }

  const isLoading = computed(() => isLoadingState.value && analysenState.value.length === 0)
  const getEinheit = (analyseId: string) =>
    analysenState.value.find((a) => a.id === analyseId)?.einheit

  return {
    isLoading: readonly(isLoading),
    analysen: readonly(analysenState),
    fetchAnalysen,
    addAnalyse,
    deleteAnalyse,
    updateAnalyse,
    analysenCount,
    copyItem,
    getEinheit
  }
})
