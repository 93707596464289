import { createI18n } from 'vue-i18n'
import de from './locales/de.json'

const i18n = createI18n({
  legacy: false,
  locale: 'de',
  messages: {
    de: { ...de }
  }
})

export const { t } = i18n.global
export default i18n
