export function useEnv() {
  const {
    DEV,
    MODE,
    BASE_URL,
    VITE_STAMMDATEN_API_URL: STAMMDATEN_API_URL,
    VITE_KEYCLOAK_REDIRECT_URI: KEYCLOAK_REDIRECT_URI,
    VITE_KEYCLOAK_REALM_URI: KEYCLOAK_REALM_URI,
    VITE_KEYCLOAK_ADMIN_REALM_URI: KEYCLOAK_ADMIN_REALM_URI,
    VITE_KEYCLOAK_AUTH_URI: KEYCLOAK_AUTH_URI,
    VITE_KEYCLOAK_CLIENT_ID: KEYCLOAK_CLIENT_ID,
    VITE_KEYCLOAK_REALM: KEYCLOAK_REALM
  } = import.meta.env
  return {
    DEV,
    MODE,
    BASE_URL,
    STAMMDATEN_API_URL,
    KEYCLOAK_REDIRECT_URI,
    KEYCLOAK_REALM_URI,
    KEYCLOAK_ADMIN_REALM_URI,
    KEYCLOAK_AUTH_URI,
    KEYCLOAK_CLIENT_ID,
    KEYCLOAK_REALM
  }
}
