export const primaryColorShades = {
  '50': '#fdf2f9',
  '100': '#fce7f5',
  '200': '#fcceec',
  '300': '#fba6dc',
  '400': '#f76fc2',
  '500': '#f044a8',
  '600': '#df2388',
  '700': '#c2146c',
  '800': '#991355',
  '900': '#85164d',
  '950': '#52052b'
}
