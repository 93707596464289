export const componentTheme = {
  datatable: {
    colorScheme: {
      light: {
        row: {
          selected: {
            color: '{datatable.row.color}'
          }
        }
      }
    }
  },
  select: {
    colorScheme: {
      light: {
        option: {
          selected: {
            color: '{select.color}',
            focus: {
              color: '{select.color}'
            }
          }
        }
      }
    }
  }
}
