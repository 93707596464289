<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'
import type { KeycloakInitOptions } from 'keycloak-js'
import { keycloak } from '@/composables/stammdatenApiComposable'
import { useEnv } from '@/composables/enviromentComposable'

const authenticated = ref<boolean>(false)

const route = useRoute()
const env = useEnv()

const layout = computed(() => {
  return route.meta.layout || DefaultLayout
})
const initOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  redirectUri: env.KEYCLOAK_REDIRECT_URI
}

onMounted(async () => {
  try {
    if (!keycloak?.authenticated) authenticated.value = await keycloak.init(initOptions)
  } catch (error) {
    console.error('Failed to initialize Keycloak:', error)
  }
})
</script>

<template>
  <div
    v-if="authenticated"
    class="h-screen"
  >
    <component :is="layout">
      <RouterView />
    </component>
  </div>
  <Toast />
  <ConfirmDialog />
</template>
