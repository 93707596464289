import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRegelgruppenStore } from '@/stores/regelgruppen/RegelgruppenStore'
import { usePatientenProfileStore } from '@/stores/patientenprofile/PatientenprofileStore'

export const useFaecherAndFocusStore = defineStore('faecher', () => {
  const index = ref(1)
  const functionsList = ref<ShortCutFunction[]>([])
  const cardWithFocus = ref<string>()
  const regelgruppenStore = useRegelgruppenStore()
  const patientenprofileStore = usePatientenProfileStore()


  const indexDisabled = computed(() => ([
    { index: 2, isDisabled: !regelgruppenStore.selectedItem },
    { index: 3, isDisabled: !patientenprofileStore.selectedItem }
  ]))

  const isIndexDisabled = (index: number) => {
    return indexDisabled.value.find(x => x.index == index)?.isDisabled
  }


  function changeIndex(newIndex: number): void {
    if (isIndexDisabled(newIndex)) return
    index.value = newIndex
    const faecherElement = document.getElementById(`main${index.value}`)
    if (!faecherElement) return
    const cards = Array.from(faecherElement.getElementsByClassName('hus-card'))
    if (cards.length > 0) {
      const topCards = cards.sort((a, b) => {
        const aRect = a.getBoundingClientRect()
        const bRect = b.getBoundingClientRect()

        return aRect.y - bRect.y
      })

      const topLeftCard = topCards.sort((a, b) => {
        const aRect = a.getBoundingClientRect()
        const bRect = b.getBoundingClientRect()

        return aRect.x - bRect.x
      })[0]

      if (topLeftCard) {
        topLeftCard.setAttribute('tabindex', '0');
        (topLeftCard as HTMLDivElement).style.visibility = 'visible'
        setTimeout(() => {
          (topLeftCard as HTMLDivElement).focus()
          const activeElement = document.activeElement
          newCardFocus(topLeftCard.id)

        }, 100)

      }
    }
  }

  const registerShortCutMethod = (focusId: string, method: ShortcutMethod, func: unknown) => {
    functionsList.value = functionsList.value.filter(x => x.focusId != focusId || x.method != method)
    functionsList.value.push({ focusId: focusId, method: method, func: func } as ShortCutFunction)
  }


  const newCardFocus = (index: string) => {
    cardWithFocus.value = index
  }
  const removeCardFocus = (index: string) => {
    if (cardWithFocus.value == index)
      cardWithFocus.value = undefined
  }

  const handleAddEvent = () => {
    const item = functionsList.value.find(x => x.focusId == cardWithFocus.value && x.method == ShortcutMethod.add)
    if (item) {
      item.func()
    }

  }

  return {
    changeIndex,
    index,
    registerShortCutMethod,
    changeCardFocus: newCardFocus,
    handleAddEvent,
    removeCardFocus,
    cardWithFocus,
    isIndexDisabled
  }
})


export interface ShortCutFunction {
  focusId: string,
  method: ShortcutMethod
  func: (...args: unknown[]) => unknown;
}

export enum ShortcutMethod {
  add,
}