import { createRouter, createWebHistory } from 'vue-router'
import BlankLayout from '@/layouts/BlankLayout.vue'

export const linkActiveClass = 'active-route'
export const linkExactActiveClass = 'active-route exact-active-route'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/LoginView.vue'),
      meta: { layout: BlankLayout }
    },
    {
      path: '/regelgruppen',
      name: 'Regelgruppen',
      component: () => import('@/views/regelgruppen/RegelgruppenMain.vue')
    },
    {
      path: '/analysen',
      name: 'Analysen',
      component: () => import('@/views/analysen/AnalysenView.vue')
    },
    {
      path: '/antibiotika',
      name: 'Antibiotika',
      component: () => import('@/views/antibiotika/AntibiotikaView.vue')
    },
    {
      path: '/resistenzmuster',
      name: 'Resistenzmuster',
      component: () => import('@/views/resistenzmuster/ResistenzmusterView.vue')
    },
    {
      path: '/keime',
      name: 'Keime',
      component: () => import('@/views/keime/KeimeView.vue')
    },
    {
      path: '/keimgruppen',
      name: 'Keimgruppen',
      component: () => import('@/views/keimgruppen/KeimgruppenView.vue')
    },
    {
      path: '/probengueter',
      name: 'Probengueter',
      component: () => import('@/views/probengueter/ProbengueterView.vue')
    },
    {
      path: '/textbausteine',
      name: 'Textbausteine',
      component: () => import('@/views/TextbausteineView.vue')
    },
    {
      path: '/gueltigkeiten',
      name: 'gueltigkeiten',
      component: () => import('@/views/gueltigkeiten/GueltigkeitenView.vue')
    },

    {
      path: '/',
      redirect: '/regelgruppen'
    }
  ],
  linkActiveClass,
  linkExactActiveClass
})

export default router
