import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import { computed, readonly, type Ref, ref } from 'vue'
import { useStammdatenApi } from '@/composables/stammdatenApiComposable'
import type { Probengut } from '@/stores/probengüter/Probengueter'
import type { PartialWithRequiredFields } from '@/models/utils'

export const useProbengueterStore = defineStore('probengüter', () => {
  const isLoadingState = ref(false)
  const stammdatenApi = useStammdatenApi()

  const probengueterState = useSessionStorage<Probengut[]>('probengueter', [])

  const updateDataInProbengueterById = (data: Probengut): boolean => {
    const index = probengueterState.value.findIndex((p) => p.id === data.id)
    if (index === -1) {
      return false
    } else {
      probengueterState.value = [
        ...probengueterState.value.slice(0, index),
        data,
        ...probengueterState.value.slice(index + 1)
      ]
      return true
    }
  }

  const insertProbengut = (data: Probengut) =>
    (probengueterState.value = [data, ...probengueterState.value])

  const fetchProbengueter = async () => {
    const req = stammdatenApi('Probengueter').get().json<Probengut[]>()
    const { data, error } = await req

    if (error.value || !data.value) return

    probengueterState.value = data.value
  }

  const fetchProbengutById = async (id: string) => {
    const req = stammdatenApi(`Probengueter/${id}`).get().json<Probengut>()
    const { data, error } = await req

    if (error.value || !data.value) return

    const ok = updateDataInProbengueterById(data.value)
    if (!ok) insertProbengut(data.value)
  }

  const createProbengut = async (
    request: Omit<Probengut, 'id' | 'updateDateTime' | 'usedByRegeln' | 'isLocked'>
  ) => {
    const req = stammdatenApi('Probengueter')
      .post({ name: request.name, kuerzel: request.kuerzel })
      .json<Probengut>()
    const { data, error } = await req

    if (error.value || !data.value) return
    insertProbengut(data.value)
  }

  const updateProbengut = async (request: PartialWithRequiredFields<Probengut, 'id'>) => {
    const oldItem = probengueterState.value.find((pg) => pg.id === request.id)
    if (!oldItem) return
    const req = stammdatenApi(`Probengueter/${request.id}`)
      .put({ ...oldItem, ...request })
      .json<Probengut>()
    const { data, error } = await req

    if (error.value || !data.value) return
    updateDataInProbengueterById(data.value)
  }

  const deleteProbengut = async (id: string) => {
    const req = stammdatenApi(`Probengueter/${id}`).delete()
    const { error } = await req

    if (error.value) return
    probengueterState.value = probengueterState.value.filter((p) => p.id !== id)
  }

  const probengutCount = computed(() => probengueterState.value.length)
  const isLoading = computed(() => isLoadingState.value && probengueterState.value.length === 0)

  return {
    probengueter: readonly<Ref<Probengut[]>>(probengueterState),
    isLoading: readonly(isLoading),
    fetchProbengueter,
    fetchProbengutById,
    createProbengut,
    updateProbengut,
    deleteProbengut,
    probengutCount
  }
})
