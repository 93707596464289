import { defineStore } from 'pinia'
import type { Regelgruppe } from '@/stores/regelgruppen/Regelgruppe'
import { computed, readonly, type Ref, ref, watch } from 'vue'
import { useSessionStorage } from '@vueuse/core'
import { addLazyQueryParamsToUrl, type LazyQueryParams } from '@/stores/regelgruppen/LazyParams'
import { useStammdatenApi } from '@/composables/stammdatenApiComposable'
import type { RegelgruppenMetaResponse } from '@/stores/regelgruppen/RegelgruppenMetaResponse'
import type { UpdateRegelgruppeRequest } from '@/stores/regelgruppen/UpdateRegelgruppeRequest'
import type { PartialWithRequiredFields } from '@/models/utils'
import { useAnalyseStore } from '@/stores/analysen/AnalysenStore'
import type { AnalyseMetaResponse } from '@/stores/regelgruppen/AnalyseMetaResponse'
import { useToast } from 'primevue/usetoast'
import { t } from '@/i18n'
import type { RegelgruppenNameResponse } from '@/stores/regelgruppen/RegelgruppenNameResponse'
import { usePatientenProfileStore } from '@/stores/patientenprofile/PatientenprofileStore'
import type { Regelposition } from '@/stores/regeln/models/Regelposition'
import { useRegelnStore } from '@/stores/regeln/RegelnStore'
import { v4 as uuidv4 } from 'uuid'
import type { AnalyseInstanzMetaResponse } from '@/stores/regelgruppen/AnalyseInstanzMetaResponse'
import { useKeimeStore } from '@/stores/keime/KeimeStore'
import type { Keim } from '@/stores/keime/Keim'
import type { Keimgruppe } from '@/stores/keimgruppen/Keimgruppe'
import { useKeimgruppenStore } from '@/stores/keimgruppen/KeimgruppeStore'

const updateDataInRegelgruppenById = (regelgruppen: Ref<Regelgruppe[]>, data: Regelgruppe) => {
  const index = regelgruppen.value.findIndex((r) => r.id === data.id)
  if (index === -1) {
    return false
  } else {
    regelgruppen.value[index] = data
    return true
  }
}

export const useRegelgruppenStore = defineStore('regelgruppen', () => {
  const stammdatenApi = useStammdatenApi()
  const analysenStore = useAnalyseStore()
  const keimStore = useKeimeStore()
  const keimGruppeStore = useKeimgruppenStore()
  const patientenProfilStore = usePatientenProfileStore()
  const regelnStore = useRegelnStore()
  const toast = useToast()

  const regelgruppenState = useSessionStorage<Regelgruppe[]>('regelgruppen', [])
  const regelgruppenNamesState = useSessionStorage<RegelgruppenNameResponse[]>(
    'regelgruppenNames',
    []
  )
  const regelgruppenWithActiveFilter = ref<Regelgruppe[]>([])
  const selectedItemId = ref<string>()
  const isLoadingState = ref(false)
  const isDetailsLoading = ref(false)
  const activeQuery = ref<LazyQueryParams>()
  const totalCount = ref<number>(0)

  const updateRegelgruppeInBothStates = (regelgruppe: Regelgruppe) => {
    const existsInState = updateDataInRegelgruppenById(regelgruppenState, regelgruppe)
    const existsInFilter = updateDataInRegelgruppenById(regelgruppenWithActiveFilter, regelgruppe)
    return { existsInFilter, existsInState }
  }

  const addRegelgruppe = async ({ name, beschreibung }: { name: string; beschreibung: string }) => {
    const temporaryGuid = uuidv4()
    const newRegelgruppe = {
      name: name,
      beschreibung: beschreibung,
      updateDateTime: undefined,
      analysen: [],
      keime: [],
      keimgruppen: [],
      patientenProfile: [],
      isLocked: false,
      id: temporaryGuid
    } as Regelgruppe
    regelgruppenWithActiveFilter.value = [newRegelgruppe, ...regelgruppenWithActiveFilter.value]
    regelgruppenState.value = [newRegelgruppe, ...regelgruppenState.value]
    const req = stammdatenApi<RegelgruppenMetaResponse>('Regelgruppen/', {})
      .post({
        name,
        beschreibung
      })
      .json()
    const { isFetching, data, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()
    if (error.value || !data.value) {
      regelgruppenWithActiveFilter.value = regelgruppenWithActiveFilter.value.filter(
        (x) => x.id !== temporaryGuid
      )
      regelgruppenState.value = regelgruppenState.value.filter((x) => x.id !== temporaryGuid)
      return
    }

    const rg1 = regelgruppenWithActiveFilter.value.find((x) => x.id == temporaryGuid)
    if (rg1) {
      rg1.id = data.value.id
    }
    const rg2 = regelgruppenState.value.find((x) => x.id == temporaryGuid)
    if (rg2) {
      rg2.id = data.value.id
    }

    selectedItemId.value = data.value.id
  }

  const setQueryParams = async (queryParams: LazyQueryParams | undefined) => {
    activeQuery.value = queryParams
    if (!queryParams) return
    fetchRegelgruppenByQueryParams().catch(() => (activeQuery.value = undefined))
  }

  const fetchRegelgruppenByQueryParams = async () => {
    const req = stammdatenApi<{ totalCount: number; payload: RegelgruppenMetaResponse[] }>(
      addLazyQueryParamsToUrl('Regelgruppen', activeQuery.value)
    )
      .get()
      .json()
    const { isFetching, data, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()
    if (error.value || !data.value) throw error.value
    data.value.payload.forEach((r: RegelgruppenMetaResponse) => {
      const i = regelgruppenState.value.findIndex((rState) => rState.id === r.id)
      if (i !== -1) regelgruppenState.value[i] = { ...regelgruppenState.value[i], ...r }
      else regelgruppenState.value.push(r)
    })
    regelgruppenWithActiveFilter.value = data.value.payload
    totalCount.value = data.value.totalCount
  }

  const getRegelgruppenNames = async () => {
    const req = stammdatenApi<Regelgruppe>('Regelgruppen/Names').get().json()
    const { isFetching, data, error } = req
    const unwatch = watch(isFetching, (v) => (isDetailsLoading.value = v))
    await req
    unwatch()
    if (error.value || !data.value) return
    regelgruppenNamesState.value = data.value
  }

  const fetchRegelgruppeById = async (id: string) => {
    const req = stammdatenApi<Regelgruppe>(`Regelgruppen/${id}`).get().json()
    const { isFetching, data, error } = req
    const unwatch = watch(isFetching, (v) => (isDetailsLoading.value = v))
    await req
    unwatch()
    if (error.value || !data.value) return false
    const { existsInState } = updateRegelgruppeInBothStates(data.value)
    if (!existsInState) {
      regelgruppenState.value.push(data.value)
    }
    return true
  }

  const selectRegelgruppe = async (id: string | undefined) => {
    if (!id) {
      selectedItemId.value = undefined
      return
    }
    selectedItemId.value = id
    await patientenProfilStore.selectPatientenProfile(undefined)
    await fetchRegelgruppeById(id)
  }

  const deleteRegelgruppe = async (id: string) => {
    const regelgruppeIndex = regelgruppenState.value.findIndex((r) => r.id === id)
    const regelgruppeToDelete = regelgruppenState.value.at(regelgruppeIndex)
    if (regelgruppeToDelete) regelgruppenState.value.splice(regelgruppeIndex, 1)

    const activeFilterIndex = regelgruppenWithActiveFilter.value.findIndex((r) => r.id === id)
    const activeFilterToDelete = regelgruppenWithActiveFilter.value.at(activeFilterIndex)
    if (activeFilterToDelete) regelgruppenState.value.splice(regelgruppeIndex, 1)

    const req = stammdatenApi(`Regelgruppen/${id}`).delete()
    const { error } = req

    if (selectedItemId.value === id) selectedItemId.value = undefined
    await req
    if (error) {
      if (regelgruppeToDelete)
        regelgruppenState.value.splice(regelgruppeIndex, 0, regelgruppeToDelete)
      if (activeFilterToDelete)
        regelgruppenState.value.splice(regelgruppeIndex, 0, activeFilterToDelete)
    }
  }

  const copyItem = async (id: string, name: string, beschreibung: string) => {
    const req = stammdatenApi<Regelgruppe>(`Regelgruppen/Copy/${id}`)
      .post({ name, beschreibung })
      .json()
    const { data, error, isFetching } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()
    if (error.value || !data.value) return
    regelgruppenWithActiveFilter.value.unshift(data.value as Regelgruppe)
    regelgruppenState.value.unshift(data.value)
  }

  const updateRegelgruppe = async (changes: PartialWithRequiredFields<Regelgruppe, 'id'>) => {
    const oldItem = regelgruppenState.value.find((i) => i.id === changes.id)
    if (!oldItem) return
    const newItem = {
      id: changes.id,
      name: changes?.name ?? oldItem?.name,
      beschreibung: changes?.beschreibung ?? oldItem?.beschreibung,
      analysen: changes?.analysen ?? oldItem?.analysen,
      keime: changes?.keime ?? oldItem?.keime,
      keimgruppen: changes?.keimgruppen ?? oldItem?.keimgruppen,
      isLocked: changes?.isLocked ?? oldItem?.isLocked
    }

    const preparedItem: UpdateRegelgruppeRequest = {
      id: newItem?.id,
      name: newItem?.name,
      beschreibung: newItem?.beschreibung,
      isLocked: newItem?.isLocked
    }
    const { existsInState, existsInFilter } = updateRegelgruppeInBothStates(newItem)

    const req = stammdatenApi<Regelgruppe>(`Regelgruppen`).put(preparedItem)
    const { error } = req
    await req

    if (error.value) {
      if (existsInState) updateDataInRegelgruppenById(regelgruppenState, oldItem)
      if (existsInFilter) updateDataInRegelgruppenById(regelgruppenWithActiveFilter, oldItem)
      return
    }
  }

  const addAnalyseFromSelectedItem = async (analyseMeta: AnalyseMetaResponse) => {
    if (!selectedItem.value) return
    const analyse = analysenStore.analysen.find((a) => a.kuerzel === analyseMeta.kuerzel)
    if (!analyse) return

    const newAnalyse = {
      id: analyse.id,
      kuerzel: analyseMeta.kuerzel,
      beschreibung: analyseMeta.beschreibung,
      einheit: analyseMeta.einheit,
      updateDateTime: undefined
    } as AnalyseInstanzMetaResponse
    const rg = { ...selectedItem.value }
    rg.analysen.push(newAnalyse)
    updateRegelgruppeInBothStates(rg as Regelgruppe)

    const req = stammdatenApi('Regelgruppen/Analyse')
      .post({
        regelgruppenId: selectedItem.value.id,
        id: analyse.id
      })
      .json()
    const { data, error } = req
    await req
    if (error.value) {
      const rg = { ...selectedItem }
      if (!rg.value) return
      rg.value.analysen = rg.value.analysen.filter((x) => x.id !== analyse.id)
      updateRegelgruppeInBothStates(data.value as Regelgruppe)
    }
    const rg2 = { ...selectedItem.value }
    if (!rg2) return
    updateRegelgruppeInBothStates(rg2 as Regelgruppe)
    const selectedRegel = regelnStore.selectedItem
    await patientenProfilStore.selectPatientenProfile(patientenProfilStore.selectedItem?.id)
    regelnStore.selectRegel(selectedRegel?.id)
  }

  const deleteAnalyseFromSelectedItem = async (analyseId: string) => {
    const regelgruppenId = selectedItem.value?.id
    const isUsed = analyseIsUsedInSelected(analyseId)
    if (!regelgruppenId) return
    if (isUsed) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: t('errors.deleteAnalyseIsInUse'),
        life: 3000
      })

      return
    }
    const req = stammdatenApi(`Regelgruppen/Analyse`).delete({
      id: analyseId,
      regelgruppenId: selectedItem.value.id
    })
    const { error } = req
    await req
    if (error.value) return
    const i = regelgruppenState.value.findIndex((r) => r.id === regelgruppenId)
    if (i === -1) return
    regelgruppenState.value[i].analysen =
      regelgruppenState.value[i].analysen?.filter((a) => a.id !== analyseId) ?? []
    //update regelpositionen and used analysen by updating patientenprofil
    const selectedRegel = regelnStore.selectedItem
    await patientenProfilStore.selectPatientenProfile(patientenProfilStore.selectedItem?.id)
    regelnStore.selectRegel(selectedRegel?.id)
  }

  const setLockAnalyseFromSelectedItem = async (analyseId: string, isLocked: boolean) => {
    const selItem = selectedItem.value
    if (!selItem) return
    const i = selItem.analysen.findIndex((a) => a.id === analyseId)
    if (i === -1) return
    const isLockedOld = selItem.analysen[i].isLocked
    selItem.analysen[i].isLocked = isLocked
    const req = stammdatenApi('Regelgruppen/Analyse').put({
      regelgruppenId: selItem.id,
      analyseId,
      isLocked: isLocked
    })
    const { error } = req
    await req
    if (!error.value) return
    selItem.analysen[i].isLocked = isLockedOld
  }

  const addKeimFromSelectedItem = async (keim: Keim) => {
    if (!selectedItem.value) return
    const k = keimStore.keime.find((k) => k.kuerzel === keim.kuerzel)
    if (!k) return

    const newKeim = {
      id: k.id,
      kuerzel: k.kuerzel,
      name: k.name
    } as Keim
    const rg = { ...selectedItem.value }
    if (!rg.keime) {
      rg.keime = []
    }
    rg.keime.push(newKeim)
    updateRegelgruppeInBothStates(rg as Regelgruppe)

    const req = stammdatenApi('Regelgruppen/Keim')
      .post({
        regelgruppenId: selectedItem.value.id,
        id: newKeim.id
      })
      .json()
    const { data, error } = req
    await req
    if (error.value) {
      const rg = { ...selectedItem }
      if (!rg.value) return
      rg.value.keime = rg.value.keime.filter((x) => x.id !== keim.id)
      updateRegelgruppeInBothStates(data.value as Regelgruppe)
    }
    const rg2 = { ...selectedItem.value }
    if (!rg2) return
    updateRegelgruppeInBothStates(rg2 as Regelgruppe)
    const selectedRegel = regelnStore.selectedItem
    await patientenProfilStore.selectPatientenProfile(patientenProfilStore.selectedItem?.id)
    regelnStore.selectRegel(selectedRegel?.id)
  }

  const addKeimgruppeFromSelectedItem = async (keimgruppe: Keimgruppe) => {
    if (!selectedItem.value) return
    const k = keimGruppeStore.keimgruppen.find((k) => k.kuerzel === keimgruppe.kuerzel)
    if (!k) return

    const rg = { ...selectedItem.value }
    if (!rg.keimgruppen) {
      rg.keimgruppen = []
    }
    rg.keimgruppen.push(keimgruppe)
    updateRegelgruppeInBothStates(rg as Regelgruppe)

    const req = stammdatenApi('Regelgruppen/Keimgruppe')
      .post({
        regelgruppenId: selectedItem.value.id,
        id: keimgruppe.id
      })
      .json()
    const { data, error } = req
    await req
    if (error.value) {
      const rg = { ...selectedItem }
      if (!rg.value) return
      rg.value.keimgruppen = rg.value.keimgruppen.filter((x) => x.id !== keimgruppe.id)
      updateRegelgruppeInBothStates(data.value as Regelgruppe)
    }
    const rg2 = { ...selectedItem.value }
    if (!rg2) return
    updateRegelgruppeInBothStates(rg2 as Regelgruppe)
    const selectedRegel = regelnStore.selectedItem
    await patientenProfilStore.selectPatientenProfile(patientenProfilStore.selectedItem?.id)
    regelnStore.selectRegel(selectedRegel?.id)
  }

  const deleteKeimFromSelectedItem = async (keimId: string) => {
    const regelgruppenId = selectedItem.value?.id
    const isUsed = keimIsUsedInSelected(keimId)
    if (!regelgruppenId) return
    if (isUsed) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: t('errors.deleteKeimIsInUse'),
        life: 3000
      })

      return
    }
    const req = stammdatenApi(`Regelgruppen/Keim`).delete({
      id: keimId,
      regelgruppenId: selectedItem.value.id
    })
    const { error } = req
    await req
    if (error.value) return
    const i = regelgruppenState.value.findIndex((r) => r.id === regelgruppenId)
    if (i === -1) return
    regelgruppenState.value[i].keime =
      regelgruppenState.value[i].keime?.filter((a) => a.id !== keimId) ?? []
    //update regelpositionen and used analysen by updating patientenprofil
    const selectedRegel = regelnStore.selectedItem
    await patientenProfilStore.selectPatientenProfile(patientenProfilStore.selectedItem?.id)
    regelnStore.selectRegel(selectedRegel?.id)
  }

  const deleteKeimgruppeFromSelectedItem = async (keimgruppenId: string) => {
    const regelgruppenId = selectedItem.value?.id
    const isUsed = keimgruppeIsUsedInSelected(keimgruppenId)
    if (!regelgruppenId) return
    if (isUsed) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: t('errors.deleteKeimgruppeIsInUse'),
        life: 3000
      })

      return
    }
    const req = stammdatenApi(`Regelgruppen/Keimgruppe`).delete({
      id: keimgruppenId,
      regelgruppenId: selectedItem.value.id
    })
    const { error } = req
    await req
    if (error.value) return
    const i = regelgruppenState.value.findIndex((r) => r.id === regelgruppenId)
    if (i === -1) return
    regelgruppenState.value[i].keimgruppen =
      regelgruppenState.value[i].keimgruppen?.filter((a) => a.id !== keimgruppenId) ?? []
    const selectedRegel = regelnStore.selectedItem
    await patientenProfilStore.selectPatientenProfile(patientenProfilStore.selectedItem?.id)
    regelnStore.selectRegel(selectedRegel?.id)
  }

  const setLockKeimFromSelectedItem = async (keimId: string, isLocked: boolean) => {
    const selItem = selectedItem.value
    if (!selItem) return
    const i = selItem.analysen.findIndex((a) => a.id === keimId)
    if (i === -1) return
    const isLockedOld = selItem.analysen[i].isLocked
    selItem.keime[i].isLocked = isLocked
    const req = stammdatenApi('Regelgruppen/Keim').put({
      regelgruppenId: selItem.id,
      keimId,
      isLocked: isLocked
    })
    const { error } = req
    await req
    if (!error.value) return
    selItem.keime[i].isLocked = isLockedOld
  }

  const analyseIsUsedInSelected = (analyseId: string) =>
    selectedItem.value?.patientenProfile?.find((p) =>
      p.usedAnalysen.find((a) => a.includes(analyseId))
    )

  const keimIsUsedInSelected = (keimId: string) =>
    selectedItem.value?.patientenProfile?.find((p) => p.usedKeime.find((a) => a.includes(keimId)))

  const keimgruppeIsUsedInSelected = (keimgruppeId: string) =>
    selectedItem.value?.patientenProfile?.find((p) =>
      p.usedKeime.find((a) => a.includes(keimgruppeId))
    )

  const handleRegelpositionUpdated = (rp: Regelposition) => {
    const toAdd = rp.regelSpezifikation !== undefined
    const pp = selectedItem.value?.patientenProfile?.find(
      (x) => x.id == patientenProfilStore.selectedItem?.id
    )
    if (!pp) return
    pp.usedAnalysen = pp.usedAnalysen.filter((x) => x !== rp.subItemId)
    if (toAdd) pp.usedAnalysen.push(rp.subItemId)
  }

  const regelgruppen = computed(() => {
    return activeQuery.value ? regelgruppenWithActiveFilter.value : regelgruppenState.value
  })
  const selectedItem = computed(() => {
    return regelgruppenState.value.find((r) => r.id === selectedItemId.value)
  })

  const isLoading = computed(() => isLoadingState.value && regelgruppen.value.length === 0)

  return {
    isLoading,
    isDetailsLoading: readonly(isDetailsLoading),
    regelgruppen: regelgruppen,
    regelgruppenNames: regelgruppenNamesState,
    selectedItem: selectedItem,
    totalCount,
    activeQuery,
    fetchRegelgruppen: setQueryParams,
    addRegelgruppe,
    selectRegelgruppe,
    deleteRegelgruppe,
    copyItem,
    updateRegelgruppe,
    addAnalyse: addAnalyseFromSelectedItem,
    deleteAnalyse: deleteAnalyseFromSelectedItem,
    setLockAnalyseFromSelectedItem,
    addKeimFromSelectedItem,
    addKeimgruppeFromSelectedItem,
    deleteKeimFromSelectedItem,
    deleteKeimgruppeFromSelectedItem,
    setLockKeimFromSelectedItem,
    getRegelgruppenNames,
    fetchRegelgruppeById,
    regelgruppenWithActiveFilter,
    regelgruppenState,
    handleRegelpositionUpdated
  }
})
