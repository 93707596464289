import { defineStore } from 'pinia'
import { computed, readonly, type Ref, ref, watch } from 'vue'
import { useStammdatenApi } from '@/composables/stammdatenApiComposable'
import type { PartialWithRequiredFields } from '@/models/utils'
import type { Keimgruppe } from '@/stores/keimgruppen/Keimgruppe'
import type { UpdateKeimgruppenRequest } from '@/stores/keimgruppen/UpdateKeimgruppenRequest'
import { v4 as uuidv4 } from 'uuid'
import type { Keim } from '@/stores/keime/Keim'
import type { CreateKeimgruppeRequest } from '@/stores/keimgruppen/CreateKeimgruppeRequest'

export const useKeimgruppenStore = defineStore('keimgruppen', () => {
  const stammdatenApi = useStammdatenApi()

  const keimgruppenState = ref<Keimgruppe[]>([])
  const isLoadingState = ref(false)
  const selectedItemId = ref<string>()

  const fetchKeimgruppen = async () => {
    const req = stammdatenApi('Keimgruppe').get().json()
    const { data, isFetching, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()

    if (error.value) return
    keimgruppenState.value = data.value as Keimgruppe[]
  }

  const keimgruppenCount = computed(() => keimgruppenState.value.length)

  const addKeimgruppe = async ({
    kuerzel,
    name,
    keime
  }: {
    kuerzel: string
    name: string
    keime: Keim[]
  }) => {
    const temporaryGuid = uuidv4()
    const newKeimgruppe = {
      id: temporaryGuid,
      name: name,
      kuerzel: kuerzel,
      keime: keime,
      updateDateTime: undefined
    } as Keimgruppe

    keimgruppenState.value.push(newKeimgruppe)

    const req = stammdatenApi<Keimgruppe>('Keimgruppe/', {})
      .post({
        kuerzel,
        name,
        keimKuerzel: keime.map((k) => k.kuerzel)
      } as CreateKeimgruppeRequest)
      .json()

    const { data, error } = req
    await req
    if (error.value || !data.value) {
      keimgruppenState.value = keimgruppenState.value.filter((x) => x.id !== temporaryGuid)
      return
    }
    const k = keimgruppenState.value.find((x) => x.id == temporaryGuid)
    if (k) k.id = data.value.id
    selectedItemId.value = data.value.id
  }

  const deleteKeimgruppe = async (id: string) => {
    const keimgruppenIndex = keimgruppenState.value.findIndex((k) => k.id === id)
    const keimgruppeToDelete = keimgruppenState.value.at(keimgruppenIndex)
    if (keimgruppeToDelete) keimgruppenState.value.splice(keimgruppenIndex, 1)

    const req = stammdatenApi(`Keimgruppe/${id}`).delete()
    const { error } = req

    if (selectedItemId.value === id) selectedItemId.value = undefined
    await req
    if (error.value && keimgruppeToDelete) {
      keimgruppenState.value.splice(keimgruppenIndex, 0, keimgruppeToDelete)
    }
    await fetchKeimgruppen()
  }

  const updateKeimgruppe = async (changes: PartialWithRequiredFields<Keimgruppe, 'id'>) => {
    const oldItem = keimgruppenState.value.find((i) => i.id === changes.id)
    if (!oldItem) return
    const newItem = {
      id: changes.id,
      kuerzel: changes?.kuerzel ?? oldItem?.kuerzel,
      name: changes?.name ?? oldItem?.name,
      keime: changes?.keime ?? oldItem?.keime,
      isLocked: changes?.isLocked ?? oldItem?.isLocked
    }

    const preparedItem: UpdateKeimgruppenRequest = {
      id: newItem?.id,
      kuerzel: newItem?.kuerzel,
      name: newItem?.name,
      keimKuerzel: newItem.keime.map((keim: Keim) => keim.kuerzel),
      isLocked: newItem.isLocked
    }
    const existsInState = updateDataInKeimgruppeById(keimgruppenState, newItem)

    const req = stammdatenApi<Keimgruppe>(`Keimgruppe`).put(preparedItem)
    const { isFetching, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()

    if (error.value) {
      if (existsInState) updateDataInKeimgruppeById(keimgruppenState, oldItem)
      return
    }

    await fetchKeimgruppen()
  }

  const updateDataInKeimgruppeById = (keimgruppen: Ref<Keimgruppe[]>, data: Keimgruppe) => {
    const index = keimgruppen.value.findIndex((r) => r.id === data.id)
    if (index === -1) {
      return false
    } else {
      keimgruppen.value[index] = data
      return true
    }
  }

  const isLoading = computed(() => isLoadingState.value && keimgruppenState.value.length === 0)

  return {
    isLoading: readonly(isLoading),
    keimgruppen: readonly(keimgruppenState),
    fetchKeimgruppen,
    createKeimgruppe: addKeimgruppe,
    deleteKeimgruppe,
    updateKeimgruppe,
    keimgruppenCount
  }
})
