import { preset } from '@/theme/preset'

export const LimbachTheme = {
  theme: {
    preset: preset,
    options: {
      prefix: 'p',
      darkModeSelector: 'dark-mode',
      cssLayer: false
    }
  },
  pt: {
    select: {
      root: {
        onkeydown: (e: KeyboardEvent) => {
          e.preventDefault()
          if ((e.key == 'ArrowDown' || e.key == 'ArrowUp') && e.ctrlKey) {
            const container = Array.from(document.getElementsByClassName('p-select-open'))
            container.forEach(x => (x as HTMLElement).click())

            const newEvent = new KeyboardEvent('keydown', {
              key: e.key,
              code: e.code,
              keyCode: e.keyCode,
              ctrlKey: e.ctrlKey,
              shiftKey: e.shiftKey,
              altKey: e.altKey,
              metaKey: e.metaKey,
              bubbles: true // Wichtig, damit das Event die DOM-Hierarchie durchläuft
            })
            setTimeout(() => {
              document.dispatchEvent(newEvent)
            }, 0)
          }
        }
      }
    }
  }
}
