import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'
import { primaryColorShades } from '@/theme/primaryColorShades'
import { lightScheme } from '@/theme/lightScheme'
import { componentTheme } from '@/theme/componentTheming'

export const preset = definePreset(Aura, {
  semantic: {
    primary: primaryColorShades,
    colorScheme: {
      light: lightScheme
    }
  },
  components: componentTheme
})
