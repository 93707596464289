import { defineStore } from 'pinia'
import { useStammdatenApi } from '@/composables/stammdatenApiComposable'
import { useSessionStorage } from '@vueuse/core'
import { computed, readonly, ref, watch } from 'vue'
import type { PatientenProfil } from '@/stores/patientenprofile/PatientenProfil'
import type { PartialWithRequiredFields } from '@/models/utils'
import type { Regelgruppe } from '@/stores/regelgruppen/Regelgruppe'
import { useRegelnStore } from '@/stores/regeln/RegelnStore'
import { useRegelgruppenStore } from '@/stores/regelgruppen/RegelgruppenStore'
import type { Regel } from '@/stores/regeln/models/regel'
import { v4 as uuidv4 } from 'uuid'
import type { PatientenProfilMetaResponse } from '@/stores/models/patientenprofile/PatientenProfilMetaResponse'

export const usePatientenProfileStore = defineStore('patientenprofile', () => {
  const stammdatenApi = useStammdatenApi()
  const regelStore = useRegelnStore()
  const regelgruppenStore = useRegelgruppenStore()

  const patientenProfileState = useSessionStorage<PatientenProfil[]>('patientenprofile', [])
  const selectedItemId = ref<string>()
  const isLoadingState = ref(false)
  const isDetailsLoadingState = ref(false)

  const updatePatientenProfileState = (patientenProfil: PatientenProfil) => {
    const index = patientenProfileState.value.findIndex((r) => r.id === patientenProfil.id)
    if (index === -1) {
      return false
    } else {
      patientenProfileState.value[index] = patientenProfil
      return true
    }
  }

  const deleteRegelFromPatientenProfil = (id: string) => {
    const pp = patientenProfileState.value.find((x) => x.regeln.find((r) => r.id == id))
    if (!pp) return
    const regelIndex = pp.regeln.findIndex((x) => x.id == id)

    pp.regeln.filter((x) => x.position > pp.regeln[regelIndex].position).map((x) => x.position--)

    const i = pp.regeln.filter((x) => x.id !== id)
    pp.regeln = i
    updatePatientenProfileState(pp)
  }

  const updateRegelFromPatientenProfil = (regel: Regel, ppId: string, oldRegelId?: string) => {
    const id = oldRegelId ?? regel.id
    const pp = patientenProfileState.value.find((x) => x.id == ppId)
    if (!pp) return
    const regelIndex = pp.regeln.findIndex((x) => x.id == id)
    if (regelIndex == -1) return
    pp.regeln[regelIndex] = regel
    updatePatientenProfileState(pp)
  }

  const addRegelFromPatientenProfil = (regel: Regel, ppId: string) => {
    const pp = patientenProfileState.value.find((x) => x.id == ppId)
    if (!pp) return
    pp.regeln.push(regel)
    updatePatientenProfileState(pp)
  }

  let debounceTimer: ReturnType<typeof setTimeout> | null = null
  let debounceChange: PartialWithRequiredFields<PatientenProfil, 'id'> | undefined = undefined

  const emitDebouncedChange = (
    patientenProfile: PartialWithRequiredFields<PatientenProfil, 'id'>,
    time: number
  ) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer)
      if (debounceChange?.geschlecht && !patientenProfile.geschlecht) {
        patientenProfile.geschlecht = debounceChange.geschlecht
      }
      if (debounceChange?.schwangerschaftsmerkmal && !patientenProfile.schwangerschaftsmerkmal) {
        patientenProfile.schwangerschaftsmerkmal = debounceChange.schwangerschaftsmerkmal
      }
    }
    debounceChange = patientenProfile
    debounceTimer = setTimeout(async () => {
      const oldItem = patientenProfileState.value.find((i) => i.id === patientenProfile.id)
      if (!oldItem) return
      const newItem: PatientenProfil = { ...oldItem, ...patientenProfile }

      updatePatientenProfileState(newItem)

      const req = stammdatenApi<Regelgruppe>(`Regelgruppen/Patientenprofil`).put(newItem)
      const { error } = req
      await req
      debounceChange = undefined

      if (error.value) {
        updatePatientenProfileState(oldItem) // Rollback bei Fehler
      }

      if (!regelgruppenStore.selectedItem?.id) return
      regelgruppenStore.fetchRegelgruppeById(regelgruppenStore.selectedItem.id)
    }, time)
  }

  const updatePatientenProfil = async (
    patientenProfile: PartialWithRequiredFields<PatientenProfil, 'id'>
  ) => {
    if (patientenProfile.geschlecht || patientenProfile.schwangerschaftsmerkmal) {
      emitDebouncedChange(patientenProfile, 800) // Debounced Update
    } else {
      if (debounceTimer) {
        clearTimeout(debounceTimer)
        debounceTimer = null
        emitDebouncedChange(patientenProfile, 0)
      }
    }
  }

  const addPatientenProfil = async (name: string) => {
    if (!regelgruppenStore.selectedItem) return

    const payload = {
      name,
      regelgruppeId: regelgruppenStore.selectedItem.id
    }

    const temporaryGuid = uuidv4()
    const newPatientenprofil = {
      name: name,
      position: regelgruppenStore.selectedItem.patientenProfile?.length
        ? regelgruppenStore.selectedItem.patientenProfile.length + 1
        : 1,
      conflictingNames: [],
      id: temporaryGuid,
      updateDateTime: undefined,
      regelCount: 0,
      usedAnalysen: []
    } as PatientenProfilMetaResponse

    regelgruppenStore.selectedItem.patientenProfile?.push(newPatientenprofil)
    const req = stammdatenApi<PatientenProfil>('Regelgruppen/Patientenprofil').post(payload).json()
    const { data, error } = req
    await req
    if (error.value || !data.value) {
      regelgruppenStore.selectedItem.patientenProfile =
        regelgruppenStore.selectedItem.patientenProfile?.filter((x) => x.id !== temporaryGuid)
    }
    const pp = regelgruppenStore.selectedItem.patientenProfile?.find((x) => x.id == temporaryGuid)
    if (pp) {
      pp.id = data.value.id
      pp.conflictingNames = data.value.conflictingNames
    }
    selectedItemId.value = data.value.id
  }

  const deletePatientenProfil = async (id: string) => {
    const patientenProfilIndex = patientenProfileState.value.findIndex((r) => r.id === id)
    const patientenProfilToDelete = {
      ...patientenProfileState.value.at(patientenProfilIndex)
    } as PatientenProfil
    if (patientenProfilToDelete)
      patientenProfileState.value = patientenProfileState.value.filter((x) => x.id !== id)
    const ppInRg = { ...regelgruppenStore.selectedItem?.patientenProfile?.find((x) => x.id == id) }
    if (ppInRg) {
      regelgruppenStore.selectedItem!.patientenProfile =
        regelgruppenStore.selectedItem!.patientenProfile?.filter((x) => x.id !== id)
      regelgruppenStore
        .selectedItem!.patientenProfile?.filter((x) => x.position > ppInRg.position)
        .forEach((x) => x.position--)
    }
    const { error } = await stammdatenApi(`Regelgruppen/Patientenprofil/${id}`).delete()

    if (error.value) {
      if (patientenProfilToDelete)
        patientenProfileState.value.splice(patientenProfilIndex, 0, patientenProfilToDelete)
    }
    if (!regelgruppenStore.selectedItem?.id) return
    regelgruppenStore.selectedItem!.patientenProfile
    await regelgruppenStore.fetchRegelgruppeById(regelgruppenStore.selectedItem!.id)
  }

  const copyPatientenProfil = async (id: string, name: string, position: number) => {
    const regelgruppenId = regelgruppenStore.selectedItem?.id
    if (!regelgruppenId) return
    const pp = regelgruppenStore.selectedItem?.patientenProfile?.find((x) => x.id == id)
    if (!pp) return
    const temporaryGuid = uuidv4()
    const newPatientenprofil = { ...pp }
    newPatientenprofil.id = temporaryGuid
    newPatientenprofil.position = regelgruppenStore.selectedItem.patientenProfile?.length
      ? regelgruppenStore.selectedItem.patientenProfile.length + 1
      : 0
    regelgruppenStore.selectedItem.patientenProfile?.push(newPatientenprofil)

    const req = stammdatenApi<PatientenProfil>(`Regelgruppen/Patientenprofil/Copy/${id}`)
      .post({ name, position })
      .json()
    const { data, error, isFetching } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()
    if (error.value || !data.value) {
      regelgruppenStore.selectedItem.patientenProfile =
        regelgruppenStore.selectedItem.patientenProfile?.filter((x) => x.id !== temporaryGuid)
    }
    const res = regelgruppenStore.selectedItem.patientenProfile?.find((x) => x.id == temporaryGuid)
    if (res) {
      res.id = data.value.id
      res.conflictingNames = data.value.conflictingNames
    }
    selectedItemId.value = data.value.id
  }

  const selectPatientenProfile = async (id: string | undefined) => {
    if (!id) {
      selectedItemId.value = undefined
      return
    }
    selectedItemId.value = id

    const req = stammdatenApi<PatientenProfil>(`Regelgruppen/Patientenprofil/${id}`).get().json()
    const { isFetching, data, error } = req
    const unwatch = watch(isFetching, (v) => (isDetailsLoadingState.value = v))
    await req
    unwatch()

    if (error.value || !data.value) return
    const existsInState = updatePatientenProfileState(data.value)

    if (!existsInState) {
      patientenProfileState.value.push(data.value)
    }
    regelStore.handleNewRegelnFromPatientenProfil(data.value.regeln)
  }

  const reorderPatientenProfil = async (id: string, position: number) => {
    const pps = regelgruppenStore.selectedItem?.patientenProfile
    if (!pps) return
    const oldPosition = pps.find((x) => x.id == id)
    if (!oldPosition) return
    if (oldPosition.position > position) {
      pps
        .filter((x) => x.position >= position && x.position < oldPosition.position)
        .map((x) => x.position++)
    } else if (oldPosition.position < position) {
      pps
        .filter((x) => x.position <= position && x.position > oldPosition.position)
        .forEach((x) => x.position--)
    }
    oldPosition.position = position
    const req = stammdatenApi<PatientenProfil[]>(`Regelgruppen/Patientenprofil/reorder`)
      .post({ id, position })
      .json()
    const { isFetching, error, data } = req
    const unwatch = watch(isFetching, (v) => (isDetailsLoadingState.value = v))
    await req
    unwatch()

    if (error.value || !regelgruppenStore.selectedItem) {
      await regelgruppenStore.fetchRegelgruppeById(regelgruppenStore.selectedItem.id)
      const ok = updatePatientenProfileState(data.value.find((x) => x.id == id))
      if (!ok)
        patientenProfileState.value = [
          data.value.find((x) => x.id == id),
          ...patientenProfileState.value
        ]
    }
    data.value.map((x) => {
      const pp = regelgruppenStore.selectedItem!.patientenProfile!.find((p) => p.id == x.id)
      if (pp) {
        pp.conflictingNames = (x as PatientenProfil).conflictingNames
      }
    })
  }

  const selectedItem = computed(() => {
    return patientenProfileState.value.find((r) => r.id === selectedItemId.value)
  })

  const isLoading = computed(() => isLoadingState.value && patientenProfileState.value.length === 0)
  const isDetailsLoading = computed(
    () => isDetailsLoadingState.value && selectedItem.value?.regeln.length === 0
  )

  return {
    isLoading: readonly(isLoading),
    isDetailsLoading: readonly(isDetailsLoading),
    patientenProfile: readonly(patientenProfileState),
    selectedItem: selectedItem,
    selectPatientenProfile,
    updatePatientenProfil,
    addPatientenProfil,
    deletePatientenProfil,
    copyPatientenProfil,
    reorderPatientenProfil,
    deleteRegelFromPatientenProfil,
    updateRegelFromPatientenProfil,
    addRegelFromPatientenProfil
  }
})
