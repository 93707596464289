export interface LazyQueryParams {
  skip: number
  amount: number
  search: string
  orderProperty: string
  orderBy: 'asc' | 'desc'
}

export function addLazyQueryParamsToUrl(url: string, query?: LazyQueryParams): string {
  if (!query) return url
  return `${url}?skip=${query.skip}&amount=${query.amount}&search=${query.search}&orderProperty=${query.orderProperty}&orderBy=${query.orderBy}`
}
